import React, { FC, useMemo } from "react";

import Link from "next/link";

import { LandlordToolsListItemType, LandlordToolsType } from "../../types";
import Subtitle from "../Subtitle";

import styles from "./styles.module.scss";

type Props = {
  content: LandlordToolsType;
};

const LandlordTools: FC<Props> = ({ content }) => (
  <section className={styles.container}>
    <Subtitle className={styles.title} content={content.title} />
    <p
      className={styles.text}
      dangerouslySetInnerHTML={{ __html: content.text }}
    />

    <div className={styles.services}>
      {content.services.map((s) => (
        <Service key={s.title} item={s} />
      ))}
    </div>
  </section>
);

export default LandlordTools;

type ServiceProps = {
  item: LandlordToolsListItemType;
};

const Service: FC<ServiceProps> = ({ item }) => {
  // All images have different sizes
  const imgWidth = useMemo(() => item.picture.width / 2, [item.picture.width]);
  const imgHeight = useMemo(
    () => item.picture.height / 2,
    [item.picture.height]
  );
  const imgMargin = useMemo(
    () => `${(128 - imgHeight) * 0.428}px 0 ${(128 - imgHeight) * 0.572}px`,
    [imgHeight]
  );
  const imgLeft = useMemo(() => `${(104 - imgWidth * 0.88) / 2}px`, [imgWidth]);

  return (
    <Link key={item.title} passHref href={item.url} prefetch={false}>
      <a className={styles.service}>
        <img
          alt={item.title}
          className={styles.serviceImage}
          height={imgHeight}
          loading="lazy"
          src={item.picture.url}
          style={{ margin: imgMargin, left: imgLeft }}
          width={imgWidth}
        />
        <div>
          <div className={styles.serviceTitle}>{item.title}</div>
          <div
            className={styles.serviceDescription}
            dangerouslySetInnerHTML={{ __html: item.description }}
          />
        </div>
      </a>
    </Link>
  );
};
